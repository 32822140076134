import React, { useContext, useEffect, useState } from 'react'
import SliderRotator from '../organisms/SliderRotator'
import Container from '../../layouts/Container/Container'
import { StateController } from './../../App'
import Button from '../atoms/Button'
import { sendDataLayer } from '../../utils/functions'

const SliderDavipuntosAwards = () => {

  const { staticContent } = useContext(StateController)
  const [davipuntos, setDavipuntos] = useState([])

  useEffect(() => {
    if (staticContent && staticContent.davipuntos) {
      setDavipuntos(staticContent.davipuntos)
    }
  }, [staticContent])
  
  const sendDatalayerInfo = () => {
    sendDataLayer({datalayer: {event: 'reedem_davipuntos', text: 'Redima sus Davipuntos', link: 'https://comunicaciones.davivienda.com/Davipuntos-lealtad'},repeat: true})
  }

  return (
    <div className='mc-slider-davipuntos-awards'>
      <Container className={'davipuntos-awards-container'}>
        <SliderRotator
          variableWidth
          slidesToScroll={1}
          davipuntosAwards
          dots
          arrows
          mobileItemsToShow={1}
          desktopItemsToShow={3}
          className={'login-slider davipuntos-awards'}
          list={davipuntos}
          timer={5000}
        />
      </Container>
     <Container>
     <span onClick={sendDatalayerInfo}>
      <Button
          className={'fit-content btn-davipuntos-awards'}
          type="primary"
          text="Redima sus Davipuntos"
          loading={false}
          status="active"
          icon="davi"
          link="https://comunicaciones.davivienda.com/Davipuntos-lealtad"
          external
        />
     </span>
     </Container>
    </div>
  )
}

export default SliderDavipuntosAwards
