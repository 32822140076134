import React, { useState, useContext } from 'react'
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import CustomModal from './CustomModal'
import Button from '../atoms/Button'
import Tag from '../atoms/Tag'

import { getPrice, sendDataLayer } from '../../utils/functions'
import { doloadRedeem } from '../../utils/helpers'
import { StateController } from './../../App'

function AwardBox({
  type,
  award,
  canRedem,
  termLink,
  termText,
  redemtionText,
  showStock,
  token,
  download,
  twoStepAuth,
  position
}) {
  const {
    setAwardSelected,
    handlerRootModal,
  } = useContext(StateController)
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [showErrorDownload, setShowErrorDownload] = useState(false)
  const [showErrorRedemtion, setShowErrorRedemtion] = useState(false)
  const [showTermsModal, setShowTermsModal] = useState(false)

  const handlerModal = () => {
    if (!download) {
      sendDataLayer({datalayer: {event: 'bond_interest',product_name: award.name ,product_price: award.price, product_type: "inteligentes", index: position},repeat: true})
    }
    
    if (download && !downloadLoading) {
      setDownloadLoading(true)

      doloadRedeem(award.id, token).then((data) => {
        console.log("result download:", data)
        setDownloadLoading(false)

        sendDataLayer({datalayer: {event: 'redownload',product_name: award.name ,product_price: award.price, product_type: "inteligentes"},repeat: true})

        if (data && (data.status === 402 || data.status === 400)) {
          setShowErrorDownload(true)
        }
      }).catch(error => {
        console.log("ERROR DOWNLOAD REDENTION:", error)
        setDownloadLoading(false)
        setShowErrorDownload(true)
      })
    } else {

      if (twoStepAuth) {
        console.log("Open Two Step Auth Factor.")
      } else {
        if (canRedem) {
          handlerRootModal()
          setAwardSelected({
            image: award.image,
            price: award.price,
            name: award.name,
            canRedem: canRedem,
            id: award.id,
            index: position
          })
        }
      }
    }
  }

  // const openModalTerms = (e) => {
  //   e.preventDefault();
  //   setShowTermsModal(true)
  // }


  return (
    <div className='mc-awards-container__items--item__container'>

      <CustomModal
        title={`btenga un xx% de descuento`}
        desc={"Lasdfsd"}
        body={"Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen. orem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen. orem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen."}
        cta="Aceptar"
        icon={false}
        image={award.image}
        show={showTermsModal} setShowModal={setShowTermsModal}
        termsOnModal={true}
        rule={"sadfasdf"}
      />

      <CustomModal
        title="Inténtelo de nuevo"
        desc={'No pudimos obtener su bono en este momento. Le recordamos probar más tarde.'}
        cta="Aceptar"
        show={showErrorDownload} setShowModal={setShowErrorDownload}
      />
      <CustomModal
        title="Inténtelo de nuevo"
        desc={'No pudimos obtener su bono en este momento. Le recordamos probar más tarde.'}
        cta="Aceptar"
        show={showErrorRedemtion} setShowModal={setShowErrorRedemtion}
      />

      {showStock && <Tag position={"left"} text={`${award.stock} Disponible${award.stock > 1 ? 's' : ''}`} />}
      {type === 1 &&
          <>
            <div className="mc-awards-container__items--item__image">
              <img src={`/assets/images/${award.image}`} alt={award.name} />
            </div>
            <div className="mc-awards-container__items--item__info">
              <div className="mc-awards-container__items--item__name">{award.name}</div>
              <div className="mc-awards-container__items--item__price">COP {getPrice(award.price)}</div>
              {termLink &&
                <p>
                  <Link to={termLink} target="_blank">
                    {termText}
                  </Link>
                </p>
              }
              <Button
                type="primary"
                text={redemtionText}
                loading={downloadLoading}
                status={canRedem ? 'active' : 'inactive'}
                onClick={(e) => handlerModal(e,award)}
              />
            </div>
          </>
      }
      {type === 2 &&
          <>
            <div className="mc-awards-container__items--item__info">
              <div 
                className="mc-awards-container__items--item__image"
                style={{"backgroundImage": `url(/assets/images/${award.image})`}}
              ></div>
              <div className="mc-awards-container__items--item__info">
                <div className="mc-awards-container__items--item__name">{award.name}</div>
                <div className="mc-awards-container__items--item__price">COP {award.price}</div>
              </div>
            </div>
            {termLink &&
              <p>
                <a href={termLink} target='_blank' rel="noreferrer">
                  {termText}
                </a>
              </p>
            }
            <Button
              type="primary"
              text={redemtionText}
              loading={false}
              status="active"
              onClick={(e) => handlerModal(e,)}
            />
          </>
      }
      {type === 3 &&
          <>
            <div className="mc-awards-container__items--item__info">
              <div 
                className="mc-awards-container__items--item__image"
                style={{"backgroundImage": `url(/assets/images/${award.image})`}}
              ></div>
              <div className="mc-awards-container__items--item__labels">
                <div className="mc-awards-container__items--item__name">{award.name}</div>
                <div className="mc-awards-container__items--item__price">{getPrice(award.price)}</div>
              </div>
            </div>
            <div className='mc-awards-container__items--item__actions'>
              {/* {termLink &&
                <p>
                  <a href={termLink} target='_blank' rel="noreferrer" onClick={openModalTerms}>
                    {termText}
                  </a>
                </p>
              } */}
              <Button
                type="primary"
                text={redemtionText}
                loading={false}
                status={canRedem ? 'active' : 'inactive'}
                onClick={(e) => handlerModal(e,)}
                icon={download ? false : canRedem ? false : 'secure'}
              />
            </div>
          </>
      }
      {type === 4 &&
          <>
            <div className="mc-awards-container__items--item__info">
              <div className="mc-awards-container__items--item__image">
                <span style={{"backgroundImage": `url(/assets/images/${award.image})`}}></span>
              </div>
              <div className="mc-awards-container__items--item__name">{award.name}</div>
              <div className="mc-awards-container__items--item__price">COP {award.price}</div>
              <Button
                type="primary"
                text={redemtionText}
                loading={false}
                status="active"
                onClick={(e) => handlerModal(e,)}
              />
              {termLink &&
                <p>
                  <a href={termLink} target='_blank' rel="noreferrer">
                    {termText}
                  </a>
                </p>
              }
            </div>
          </>
      }
    </div>
  )
}

const mapState = ({ user: { token } }) => {

  return {
    token
  }
}

export default connect(mapState)(AwardBox)