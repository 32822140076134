import React from 'react'
import { useNavigate } from 'react-router-dom'

import Icon from './Icon'

function Button({
  type,
  text,
  loading,
  status,
  onClick,
  link,
  icon,
  className,
  external,
  pdf,
  pdf2,
  reloader
}) {
  const navigate = useNavigate()
  const handler = () => {

    if (status === 'active' && !loading) {

      if (pdf) {
        console.log("descargar esto", link)
        const a = document.createElement('a');
        a.href = link;
        a.download = 'guía_de_viajes_compras_maestras_davivienda.pdf';
        a.click();
      } else if (pdf2) {
        const a = document.createElement('a');
        a.href = link;
        a.download = 'tarjeton-interactivo.pdf';
        a.click();
      } else {
        link
          ? external
            ? window.open(link, '_blank')
            : navigate(link)
          : onClick 
            ? onClick()
            : console.log("No se ejecuta nada")
      }
    }
  }

  return (
    <div
      className={`mc-button-container mc-button-container__${type}`}
      onClick={handler}
    >
      <button className={`slow_ani mc-button-container__status--${status} ${className ? className : ''}`}>
        {loading
          ? <img src='/assets/images/preloader_1.gif' alt='loader' className='inline-loader' />
          : icon 
            ? <><Icon name={icon} />{text}</>
            : text
        }
      </button>
    </div>
  )
}

export default Button