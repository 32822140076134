import React, { useState, useEffect } from 'react'
import { sendDataLayer } from '../../utils/functions'

function Accordeon({
  allItems, 
  setAllItems, 
  title, 
  content,
  opened,
  isMajorAward
}) {
  const [show, setShow] = useState(opened ? opened : false)

  const toggler = (e) => {
    console.log("Tocker!")
    setAllItems(!allItems)

    if (e.target.closest('.mc-accordeon-container.active')) {
      return false
    }

    setTimeout( () => {
      setShow(true)
      if (isMajorAward) {
        sendDataLayer({datalayer: {event: 'prize_actions', text: title},repeat: true})
      } else {
        sendDataLayer({datalayer: {event: 'frequently_questions',question: title},repeat: true})
      }
      
    }, 50 )
  }

  useEffect(() => {
    setShow(false)
    
  }, [allItems])

  return (
    <div className={`mc-accordeon-container slow_ani ${show ? 'active' : ''}`}>
      <div
        className="mc-accordeon-toggle slow_ani"
        onClick={toggler}
      >
        {title}
        <div className="mc-accordeon-toggle-icon">
          {show ? '-' : '+'}
        </div>
      </div>
      {show &&
        <div className="mc-accordeon-content">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      }
    </div>
  )
}

export default Accordeon