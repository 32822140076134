import React, { useState } from 'react'
import Container from '../../layouts/Container/Container'
import Columns, { Col } from '../../layouts/Columns/Columns'
import Accordeon from '../atoms/Accordeon'

const SectionFaqrsMayorAward = () => {
  const [allItems, setAllItems] = useState(false)
  
  return (
    <section className='SectionFaqrsMayorAward'>
      <article>
      <Container>
        <Columns desktop={2} mobile={1}>
        <Col top>
        <div className="faq-content">
          {/* <h3 className='line-title line-title-center-lg'>Preguntas <br /> frecuentes</h3> */}
          <p className='faq-description'>Dígale adiós a las dudas y hola a su próxima aventura.</p>
          <img src="/assets/images/separator.png" alt="Titulos" className='separator-title' />
        </div>
        </Col>
        <Col>
          <div className="group-items">

            <div className="item">
              <div className={`mc-faqs-container mc-faqs-container-type-1`}>
                <Accordeon
                  opened={true}
                  key={1}
                  title="¿Qué es el premio mayor?"
                  content="El Premio Mayor consta de un viaje para dos (2) personas al destino de elección entre tres (3) opciones: Paris, Ciudad de México (experiencia carrera de automovilismo en México) y La Patagonia Argentina. El viaje incluye: Tiquetes ida y regreso en clase turista desde Bogotá para el ganador y un acompañante, traslados aeropuerto-hotel-aeropuerto, alojamiento en hotel según disponibilidad, desayunos, almuerzo y cenas. Cada paquete incluye experiencias e itinerarios diferentes."
                  setAllItems={setAllItems}
                  allItems={allItems}
                  isMajorAward={true}
                />
              </div>
            </div>
            <div className="item">
              <div className={`mc-faqs-container mc-faqs-container-type-1`}>
                <Accordeon
                  opened={true}
                  key={1}
                  title="¿Cómo ganarme el premio mayor?"
                  content="<p>Al finalizar la vigencia de la Campaña, se otorgará un Premio Mayor a los dos (2) Participantes que hayan cumplido la primera Meta asignada la mayor cantidad de veces, durante la vigencia de la Campaña. En caso de presentarse un empate, el criterio que utilizará El Banco para seleccionar los ganadores del Premio Mayor será premiar a los dos (2) Participantes que realizaron la mayor cantidad de transacciones en el menor tiempo con sus Tarjeta(s) Participante(s), durante la vigencia de la Campaña. </p>"
                  setAllItems={setAllItems}
                  allItems={allItems}
                  isMajorAward={true}
                />
              </div>
            </div>
            <div className="item">
              <div className={`mc-faqs-container mc-faqs-container-type-1`}>
                <Accordeon
                  opened={true}
                  key={1}
                  title="¿Quién es responsable de la documentación y los trámites necesarios para el viaje?"
                  content="La documentación requerida para el viaje es responsabilidad única del viajero y su acompañante. El Banco y Mastercard no realizarán ningún trámite de documentación. Las solicitudes de visa y costos asociados corren por cuenta del ganador, y la aceptación de la misma documentación es responsabilidad completa y única de las autoridades del país competente. "
                  setAllItems={setAllItems}
                  allItems={allItems}
                  isMajorAward={true}
                />
              </div>
            </div>
            <div className="item">
              <div className={`mc-faqs-container mc-faqs-container-type-1`}>
                <Accordeon
                  opened={true}
                  key={1}
                  title="¿La Campaña incluye algún elemento de suerte o azar para determinar al ganador?"
                  content="La dinámica de esta Campaña no cuenta con ningún tipo de suerte o azar para ganar, toda vez que se premiará a los Participantes que cumplan las condiciones descritas en la Campaña. "
                  setAllItems={setAllItems}
                  allItems={allItems}
                  isMajorAward={true}
                />
              </div>
            </div>
            <div className="item">
              <div className={`mc-faqs-container mc-faqs-container-type-1`}>
                <Accordeon
                  opened={true}
                  key={1}
                  title="¿En qué momento puedo elegir el destino?"
                  content="Una vez el participante sea notificado por El Banco que
es ganador del Premio Mayor, se le informará que le será
enviada el acta de entrega del premio al correo
electrónico registrado, en la cual deberá especificar el
destino al que desea viajar, y se le indicará el correo
electrónico al que podrá enviarla o la oficina de
Davivienda más cercana a donde deberá acercarse de
manera presencial para radicar el acta, además, se le
informarán los datos la persona de contacto que lo
apoyará para que inicie el trámite correspondiente a la
planeación del viaje. "
                  setAllItems={setAllItems}
                  allItems={allItems}
                  isMajorAward={true}
                />
              </div>
            </div>
            <div className="item">
              <div className={`mc-faqs-container mc-faqs-container-type-1`}>
                <Accordeon
                  opened={true}
                  key={1}
                  title="¿Cómo se informarán las fechas del viaje y quién asume los costos adicionales por cambios en el itinerario o actividades?"
                  content="Las posibles fechas del viaje serán informadas al ganador a través de la agencia de viajes una vez este sea notificado como ganador. Las modificaciones, ya sea por itinerario, actividades, o fechas de viaje propuestas por la agencia que impliquen gastos adicionales (por temporada, cercanía del viaje, consumo, entre otros) serán asumidas por el ganador."
                  setAllItems={setAllItems}
                  allItems={allItems}
                  isMajorAward={true}
                />
              </div>
            </div>

            

          </div>
        </Col>
        </Columns>
      </Container>
      </article>
    </section>
  )
}

export default SectionFaqrsMayorAward
